<template>
    <div class="loginTeacher">
        <el-card>
        <div class="selectSchool">
                    <div class="tip">填写教师姓名或手机号 : </div>
                    <div class="tip">
                        <el-input placeholder="请输入内容" v-model="searchValue" @change="searchTeacherList" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="searchTeacherList"></el-button>
                        </el-input>
                    </div>
                    <div v-if="loginTableData.length > 0">
                        <el-table
                            :data="loginTableData"
                            style="width: 100%">
                            <el-table-column
                            label="头像"
                            >
                            <template slot-scope="scope">
                                <img class="wx_img" :src="scope.row.wx_img">
                                <!-- <div> {{scope.wx_img}}</div> -->
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="nickname"
                            label="昵称"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            label="姓名"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="tel"
                                label="电 话"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="school_name"
                                label="学 校"
                                >
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                    size="mini"
                                    type="primary"
                                    @click="login(scope.row.id)">登录</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-card>
    </div>
</template>
<style lang="scss" >

     @import "@/assets/css/manager/other/loginTeacher.scss";
</style>
<script>
    import loginTeacher from "@/assets/js/manager/other/loginTeacher.js";
    export default {
        ...loginTeacher
    };
</script>